// Bootstrap variables overrides
$body-bg: #f2f2f2;
$black: #000;
$white: #fff;
$white-2: #f2f4ff;
$accent: #425cbb;
$jk-blue: #222e63;
$jk-red: #bf142b;
$jk-gray: #43425d;
$light-gray: #f0f0f7;
$light-gray-2: #d9d9d9;
$dark-gray: #474b4f;

// Bootstrap variables
$gray: #aaaeb3;
$secondary: $gray;
$warning: #ffeb3b;
$success: #4caf50;
$danger: #f44336;
$red: $jk-red;
$blue: $accent;
$indigo: $accent;
$info: $jk-blue;
