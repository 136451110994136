@import 'src/styles/breakpoints';
@import 'src/styles/variables';

.viewOrderContainer {
  display: flex;
  flex-wrap: wrap;

  .pickUpContainer,
  .deliveryContainer,
  .summaryContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

@include media-breakpoint-up(sm) {
  .viewOrderContainer {
    .pickUpContainer,
    .deliveryContainer {
      display: flex;
      flex-direction: column;
      width: calc(50% - 7.5px);
      margin: 0;
    }

    .pickUpContainer {
      margin-right: 7.5px;
    }

    .deliveryContainer {
      margin-left: 7.5px;
    }

    .summaryContainer {
      flex-direction: row;
      margin-top: 1em;

      .driverContainer,
      .totalContainer {
        display: flex;
        flex-direction: column;
        width: calc(50% - 7.5px);
      }

      .driverContainer {
        margin-right: 7.5px;
      }

      .totalContainer {
        margin-left: 7.5px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .viewOrderContainer {
    .pickUpContainer,
    .deliveryContainer,
    .summaryContainer {
      display: flex;
      flex-direction: column;
      width: calc(33% - 7.5px);
    }

    .deliveryContainer {
      margin: 0 7.5px;
    }

    .summaryContainer {
      flex-direction: column;
      margin: 0;

      .driverContainer,
      .totalContainer {
        display: flex;
        flex-direction: column;
        margin-left: 0.5em;
        margin-right: 0;
        width: 100%;
      }

      .totalContainer {
        margin-top: 1em;
      }
    }
  }
}
