@import 'src/styles/breakpoints.scss';

.layoutContainer {
  margin-top: 30px; 
  display: flex;
  flex-direction: column;
  min-height: 75vh;
}

@include media-breakpoint-down(xs) {
  .layoutContainer {
    margin-top: 10px;
  }
}
