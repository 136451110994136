.footer {
  position: absolute;
  bottom: 16px;
  font-size: 14px;
  color: #b9bdc1;
  text-align: center;
  width: 100%;
}
.footerInside {
  background-color: black;
  bottom: 0px;
  font-size: 14px;
  color: #b9bdc1;
  text-align: center;
  margin-top: 4rem;
  width: 100%;
  padding: 40px;
  background-image: url('../../../assets/images/footerBackground.png');
  background-repeat: no-repeat;
}
img {
  margin-bottom: 10px;
}
