.alert {
  border-radius: 0;
  font-size: .875em;
  font-weight: 500;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.alert.alert-primary {
  color: $accent;
}

.alert.alert-primary::after {
  background-color: $accent;
  content: "";
  display: block;
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 3px;
}
