@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/breakpoints';

/* Account Form Content */
.container {
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 2px 1px #e0dbdb;
  padding: 30px;
  margin-top: 15px;
}

.pageTitle {
  font-weight: 500;
}
