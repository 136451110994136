.btn { 
  padding: 1.3em;
  font-family: Montserrat;
  font-weight: 500;
  font-size: .75em;
}

.btn.btn-transparent {
  background-color: transparent;

  &:hover {
    background-color: $light-gray-2;
  }

  &.no-hover:hover {
    background-color: transparent;
  }
}

.btn.btn-white {
  background-color: $white;
}
