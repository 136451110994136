@import './variables';

.rdt_Table .rdt_TableRow:nth-child(even) {
  background-color: #f2f4ff;
  border-bottom: 0;
}

.rdt_Table {
  .rdt_TableCol_Sortable {
    position: relative;

    div {
      margin-left: 18px;
    }

    .__rdt_custom_sort_icon__ {
      position: absolute;

      i {
        opacity: 1 !important;
        font-size: 16px !important;
        transform: rotate(0) !important;
      }
    }
  }
}

.alert.alert-success {
  background-color: $success;
  color: $white;
}

.alert.alert-warning {
  background-color: $warning;
  color: #a57f13;
}

.alert.alert-danger {
  background-color: $danger;
  color: $white;
}

a.mapboxgl-ctrl-logo {
  display: none !important;
}