@import 'src/styles/breakpoints.scss';

$table-header: $white;
$table-header-border: $white;
$table-border: $light-gray;
$row-bg: $white;
$row-bg-hover: $light-gray-2;
$black: #000;

.table-container {
  display: block;
  margin: 2em auto;
  width: 100%;
  max-width: 100%;
}

.flex-table {
  display: flex;
  flex-flow: row wrap;
  transition: 0.5s;

  // header
  &:first-of-type .flex-table-row {
    @extend .h6;
    background: $white;
    border: none;
    color: $black;
    font-size: 0.75em;
    text-transform: uppercase;
    text-align: left;
  }

  &.data-row .flex-table-row {
    border-bottom: solid 1px $table-border;
  }
  &.data-row:last-child .flex-table-row {
    border-bottom: none;
  }

  &:hover {
    background: $row-bg-hover;
    transition: 500ms;
  }
}

.flex-table-row {
  width: calc(100% / 4);
  text-align: left;
  padding: 0.5em 0.5em;
  &.columns-3 {
    width: calc(100% / 3);
  }
}

@media all and (max-width: 767px) {
  .flex-table-row {
    width: calc(100% / 3); //1px = border right

    &.first {
      width: 100%;
    }
  }
}

@media all and (max-width: 430px) {
  .flex-table {
    .flex-table-row {
      border-bottom: 0;
    }
    .flex-table-row:last-of-type {
      border-bottom: solid 1px $table-border;
    }
  }

  .header {
    .flex-table-row {
      border-bottom: solid 1px;
      text-transform: uppercase;
    }
  }

  .flex-table-row {
    width: 100%; //1px = border right

    &.first {
      width: 100%;
      border-bottom: solid 1px $table-border;
    }
  }
}
