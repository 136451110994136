.subtitle {
  font-size: 13px;
  color: #AAAEB3;
  margin-bottom: 30px;
}

.instruction {
  position: relative;
  top: -2px;
}

.passwordTitle {
  margin-bottom: 2px !important;
}

.colorBar {
  background-color: #425cbb !important;
}

.circleIcon {
  color: green;
}

.errorIcon {
  color: red;
}