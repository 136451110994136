@import 'src/styles/breakpoints.scss';

.attachments {
    width: 100%;
    max-width: 100%;
    &__container__file {
        display: flex;
        justify-content: space-between;
        border: solid 1px $light-gray;
        border-radius: 10px;
        margin: 5px;
        padding: 10px;
        cursor: pointer;
        &:hover {
            background-color: #fafafa;
        }
        &--link{
            color: $accent; 
        }
    }
}
