.appNavLink:hover,
.navLink:focus,
.navLink:hover {
  text-decoration: none;
  background-color: rgba(#aaaeb3, 0.25) !important;
}

.collapse.navbar-collapse {
  height: 75px;
}

/* XS */
@media (min-width: 434px) {
  .appNavLink {
    color: #aaaeb3 !important;
    display: table-cell !important;
    font-family: 'Montserrat';
    font-weight: 500;
    height: 78px;
    padding-bottom: 0.75em !important;
    padding-top: 0.75em !important;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle !important;
  }
}

/* md */
@media (min-width: 768px) {
  .appNavLink {
    height: 78px;
    font-size: 0.625rem;
  }
}

/* lg */
@media (min-width: 992px) {
  .appNavLink {
    font-size: 0.75rem;
    padding: 1.75em !important;
  }
}
