@import 'src/styles/breakpoints.scss';

$defaultMargin: 6px;

.statusIcon {
  font-size: 10px;
  padding-right: 8px;
}

.filterContainer {
  margin-left: -$defaultMargin;
  margin-right: -$defaultMargin;
}

.filter {
  flex: 1;
  min-width: 180px;
  margin-left: $defaultMargin;
  margin-right: $defaultMargin;
}

@include media-breakpoint-up(md) {
  .filter {
    max-width: 180px;
  }
}
