@import 'src/styles/variables';

.accordion {
  .card {
    &__header {
      background-color: $light-gray;
      border: solid 1px $light-gray-2;
      &.rounded-top {
        border-radius: 0.25rem 0.25rem 0rem 0rem;
      }
      &.rounded-bottom {
        border-radius: 0rem 0rem 0.25rem 0.25rem;
      }
      &--title {
        width: 95%;
        margin-bottom: 0rem;
        a {
          // width: 100%;
          text-align: left;
          font-weight: 600;
          font-size: 1em;
          padding: 1rem;
        }
        a:before {
          content: '\f067';
          font-family: 'Font Awesome 5 Free';
          font-weight: 600;
          margin-right: 1em;
        }
        a[aria-expanded='true']:before {
          content: '\f068';
          font-family: 'Font Awesome 5 Free';
          font-weight: 600;
        }
      }
    }
    &__body {
      border: solid 1px $light-gray-2;
      border-radius: 0rem 0rem 0.25rem 0.25rem;
      &--content {
        overflow: hidden;
      }
    }
  }
}
