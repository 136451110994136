@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/breakpoints';
@import 'src/styles/breakpoints.scss';

/* Login Form */
.formDestination {
  width: 400px !important;
}

.formInput {
  border: 1px solid #474b4f !important;
  width: 400px !important;
  height: 40px !important;
  font-size: 16px !important;
}

.formInput:focus {
  box-shadow: 0 0 0 1px rgba(61, 66, 72, 0.25) !important;
}

.formInput::-webkit-input-placeholder {
  color: #aaaeb3 !important;
  font-size: 14px;
}

.form_actions {
  justify-content: space-between;
}

.formSubmit {
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: 600;
  background-color: #425cbb;
  border-radius: 5px;
  color: #fff;
  margin-top: 20px;
  padding: 14px 40px;
  font-size: 12px;
  box-shadow: none;
  border: none;
}

.formSubmit:hover {
  background-color: #516fdd;
}

.label {
  display: contents !important;
  color: #000 !important;
  font-weight: bold;
  font-size: 12px;
}

.formSubmitDisabled {
  font-family: 'Montserrat';
  font-weight: 600;
  background-color: #7486cd;
  border-radius: 5px;
  color: #fff;
  margin-top: 20px;
  padding: 14px 40px;
  font-size: 12px;
  box-shadow: none;
  border: none;
  pointer-events: none;
}

@include media-breakpoint-only(xs) {
  .page {
    justify-content: start !important;
    padding-top: 15vh;
  }

  .formInput {
    width: 100% !important;
  }

  .form_actions {
    flex-direction: column;
    justify-content: center;
  }
}
