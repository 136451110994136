.appNavDropdown {
  border: none !important;
  border-radius: 0 !important;
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.161));
  left: auto !important;
  margin-top: 0 !important;
  padding: 0 !important;
  right: 0;
}

.appNavDropdownItem {
  align-items: center !important;
  color: #000 !important;
  display: flex !important;
  font-family: 'Montserrat' !important;
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  height: 45px !important;
  justify-content: space-between !important;
  padding-left: 1.5em !important;
  text-transform: uppercase !important;
}

.appNavDropdown > .appNavDropdownItem:last-child {
  border-top: 1px solid #707070 !important;
}
