.loading_text {
  font-size: 14px;
}

.over_screen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  background: rgb(0 0 0 / 75%);
  transition: opacity 0.2s;
}

.over_screen > .loading_text{
  color: white;
  font-size: 16px;
}
