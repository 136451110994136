@import 'src/styles/breakpoints';
@import 'src/styles/variables';

.viewOrderContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -10px;

  .summaryContainer {
    flex: 1;
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.icon {
  font-size: 12px;
  padding-right: 8px;
}

.green {
  color: #2ecc71;
}

.red {
  color: #e74c3c;
}