// Variables override
@import 'src/styles/variables';
@import '~mapbox-gl/dist/mapbox-gl.css';

.container {
  padding: 10px;
}

.eyeIcon {
  color: $accent;
}

.marker {
  color: $jk-red;
}

.title {
  font-size: 12px;
  text-align: center;
  position: relative;
  top: -15px;
  span {
    position: relative;
    left: 8px;
    bottom: 3px;
  }
}

.formSubmit {
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: 600;
  background-color: $accent;
  border-radius: 5px;
  color: #fff;
  padding: 14px 40px;
  margin: auto;
  font-size: 12px;
  box-shadow: none;
  margin-top: 20px;
  border: none;
}

.formSubmitCancel {
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: 600;
  border-radius: 5px;
  color: black;
  padding: 14px 40px;
  margin: auto;
  font-size: 12px;
  box-shadow: none;
  margin-top: 20px;
  border: none;
}

.label {
  display: contents !important;
  color: #000 !important;
  font-weight: bold;
  font-size: 12px;
}

.pointer {
  cursor: pointer;
}

.backArrow {
  cursor: pointer;
}
.plusIcon {
  margin-right: 10px;
}

.recipientName {
  margin-top: 20px;
}
