@import 'src/styles/variables';

.addressItem {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 1px #e0dbdb;
  padding: 20px;
  margin-bottom: 10px;
  width: 100%;
  height: 160px;
  cursor: pointer;
}

.smText {
  font-size: 12px;
  position: relative;
  bottom: 5px;
}

.disableContainer {
  margin-top: 20px;
  span {
    font-size: 12px;
    position: relative;
    bottom: 0px;
    left: 3px;
    color: $accent;
    font-weight: bold;
  }
}

.marker {
  color: $jk-red;
}
