/* Bootstrap navbar overrides */
.navbar-nav > .nav-item.active {
  background-color: rgba(#aaaeb3, 0.25) !important;
}

.navbar-nav > .nav-item.active > .nav-link {
  color: $white !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #aaaeb3 !important;
  color: $white !important;
  font-weight: 600;
}

/* Disable white dots box in mozilla */
.navbar-inverse .navbar-nav > .active > a:focus {
  outline: 0;
}
