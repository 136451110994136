body {
  font-family: Montserrat, 'Open Sans', sans-serif;
}

// custom switch toggle
.custom-control-input:checked ~ .custom-control-label::before {
  color: $white;
  border-color: $accent !important;
  background-color: $accent !important;
}

// tabs profile
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: $accent;
  font-weight: bold;
  border-bottom: 2px solid $accent;
  font-size: 14px;
}
.nav-tabs .nav-link {
  border: none;
  font-size: 13px;
  color: black;
  font-weight: bold;
}
