h1, .h1 {
  font-weight: 600;
  font-size: 2.625em;
}

h2, .h2 { 
  font-weight: 600;
  font-size: 1.75em;
}

h3, .h3 {
  font-weight: 600;
  font-size: 1.66em;
}

h3, .h3 {
  font-weight: 600;
  font-size: 1.125em;
}

h6, .h6 { 
  font-weight: 600;
  font-size: .75em;
}

p, .p { 
  font-weight: 300;
  font-size: 1em;
}