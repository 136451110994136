.badge {
    text-shadow: none;
    font-weight: 600;
    background-color: #e6e6e6;
    font-size: 11px;
    padding: 5px;
    margin: 1em;
    color: #626262;
    border-radius: 10px;
    vertical-align: middle;
}
