@import 'src/styles/breakpoints.scss';

%base_container {
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 2px 1px #e0dbdb;
  padding: 40px;
}

/* Page Container */
.page, .sign_up_page {
  background-color: #f2f2f2;
  font-family: 'Montserrat';
  font-weight: 300;
  justify-content: center;
}

/* Sign Up Form*/
.sign_up_container {
  @extend %base_container;
  width: 420px;
  .header_subtitle {
    margin: 0;
  }
}

/* Login Content */
.container {
  @extend %base_container;
}

.header_logo {
  width: 200px;
}

.header_subtitle {
  font-size: 16px;
  color: #b7b7b7;
  font-family: 'Lora';
  margin: 20px 0;
}

.content {
  margin-top: 40px;
}

/* Login Form */
.form_input {
  border: 1px solid #474b4f !important;
  width: 340px !important;
  height: 50px !important;
  font-size: 16px !important;
}

.form_input:focus {
  box-shadow: 0 0 0 1px rgba(61, 66, 72, 0.25) !important;
}

.form_input::-webkit-input-placeholder {
  color: #aaaeb3 !important;
}

.form_actions {
  justify-content: space-between;
}

.login_others_links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.login_other_link_item {
  flex: 33.33%;
}

.form_submit {
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: 600;
  background-color: #425cbb;
  border-radius: 5px;
  color: #fff;
  margin-top: 40px;
  padding: 14px 40px;
  font-size: 12px;
  box-shadow: none;
  border: none;
  &.sign_up {
    margin-top: 20px;
  }
}

.form_submit:hover {
  background-color: #516fdd;
}


@include media-breakpoint-only(xs) {
  .page {
    justify-content: start !important;
    padding-top: 15vh;
  }

  .sign_up_page {
    justify-content: start !important;
    padding-top: 5vh;
  }

  .container, .sign_up_container {
    width: 90%;
    padding: 8px 24px 24px;
  }

  .form_input {
    width: 100% !important;
  }

  .form_actions {
    flex-direction: column;
    justify-content: center;
  }

  .form_submit {
    margin-top: 10px;
  }
}
