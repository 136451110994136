@import 'src/styles/variables';
@import 'src/styles/breakpoints.scss';

.wrapper {
  width: 100%;
}

.bold {
  font-weight: 600;
}

.title {
  @extend .bold;
  font-size: 16px;
}

.titleLarge {
  @extend .bold;
  font-size: 36px;
}

.subtitle {
  @extend .bold;
  font-size: 20px;
}

.label {
  @extend .bold;
  font-size: 12px;
  text-transform: uppercase;
}

.cell {
  padding: 8px 0;
  font-size: 14px;
}

.statusItem {
  flex: 1;
}

.statusIconContainer {
  position: relative;
}

.activeStatus .statusIconContainer {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $accent;
  color: #fff;
  font-size: 30px;
  margin-top: -33px;

  &.statusIcon {
    font-size: 28px;
  }
}

.completedStatus .statusIconContainer::before {
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: -10px;
  left: -8px;
  content: '\f058';
  @extend .bold;
  color: $success;
}

.statusIcon {
  font-size: 24px;
}


@include media-breakpoint-up(md) {
  .statusIcon {
    width: 24px;
  }
}

.statusLabel {
  padding-top: 10px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 7px;
}

@include media-breakpoint-up(lg) {
  .statusLabel {
    font-size: 10px;
  }
}

.viewOrder {
  padding: 14px 36px;
  background-color: #fff;
  border: 2px solid #000;
  color: #000;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;

  &:hover {
    color: $jk-blue;
    text-decoration: none;
    background-color: #F2F4FF;
  }
}
