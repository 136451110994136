@import 'src/styles/variables';

.statusBar {
  $steps-count: 6;
  $line-width: 2px;
  $bullet-size: 18px;
  $bullet-size-small: $bullet-size / 2;
  $uncomplete-step-color: $gray;
  $active-bg-color: $accent;
  li {
    text-transform: uppercase;
    font-weight: 500;
    font-size: .75rem;
    list-style-type: none;
    position: relative;
    float: left;
    width: 100% / $steps-count;
    text-align: center;
    color: $active-bg-color;
  }

  .activeStatus ~ li {
    color: $uncomplete-step-color;
  }

  li:before {
    counter-increment: wizard;
    content: '';
    display: block;
    background-color: $active-bg-color;
    border: $line-width solid $body-bg;
    text-align: center;
    width: $bullet-size;
    height: $bullet-size;
    line-height: $bullet-size;
    border-radius: $bullet-size;
    position: relative;
    left: 50%;
    margin-bottom: $bullet-size / 2;
    margin-left: $bullet-size * -0.5;
    z-index: 1;

    .activeStatus ~ & {
      background-color: $gray;
      color: $uncomplete-step-color;
      border-color: $uncomplete-step-color;
    }
  }

  li + li {
    &:after {
      content: '';
      display: block;
      width: 100%;
      background-color: $active-bg-color;
      height: $line-width;
      position: absolute;
      left: -50%;
      top: $bullet-size / 2;
      z-index: 0;
    }
  }

  .activeStatus:before,
  .activeStatus ~ li:before {
    height: $bullet-size-small;
    width: $bullet-size-small;
    line-height: $bullet-size-small;
    background-color: $white;
    border: 2px solid $gray;
    top: $bullet-size-small / 2;
    margin-bottom: 15px;
  }
  
  .activeStatus:before {
    border: 2px solid $accent;
  }

  .activeStatus ~ li:after {
    background-color: $uncomplete-step-color;
  }

  .activeStatus > i.completedStatusIcon,
  .activeStatus ~ li > i.completedStatusIcon {
    display: none;
  }

  .completedStatusIcon {
    position: absolute;
    top: 5px;
    left: 46%;
    color: white !important;
    z-index: 1;
  }
}