.font-lighter {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 400;
}

.font-semibold {
  font-weight: 500;
}

.font-bold {
  font-weight: 600;
}

.text-small {
  font-size: .75em;
}

.text-default {
  color: $gray;
}

.text-wrap {
  overflow-wrap: break-word;
}
