.appHeader {
  background-color: #000;
  min-height: 70px;
  display: flex;
  place-items: center center;
}

.appNavbar {
  padding: 0 !important;
}
