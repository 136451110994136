@import 'src/styles/breakpoints.scss';

$headerHeight: 71px;

.wrapper {
  height: 100vh;
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  right: 0;
  z-index: 1000;
  box-shadow: -3px 0 12px 0 rgba(0, 0, 0, 0.16);
  transform: translateX(100%);
  transition: transform 0.3s ease-out;

  &.open {
    transform: translateX(0);
  }
}

@include media-breakpoint-up(md) {
  .wrapper {
    width: 50%;
  }
  .wrapperAddressForm {
    width: 30% !important;
  }
}


.header {
  font-size: 20px;
  padding: 20px 0;
  border-bottom: 1px solid #F0F0F7;

  .back {
    cursor: pointer;
  }

  .title {
    font-weight: 600;
    font-size: 18px;
  }
}

.content {
  overflow-y: auto;
  height: calc(100vh - 71px);
}

