.appLogo {
  margin-bottom: 0;
}

@media (max-width: 434px) {
  .appLogo {
    max-width: 115px;
    max-height: 27px;
  }
}

/* md */
@media (min-width: 768px) {
  .appLogo {
    margin-right: 1em;
  }
}

/* lg */
@media (min-width: 992px) {
  .appLogo {
    margin-right: 14em;
  }
}
