@import 'src/styles/breakpoints.scss';

.card {
  flex: 1 1 200px;
  height: 260px;
  margin-right: 0;
  background-color: #fff;
  font-weight: 600;
  border-radius: 5px;
  box-shadow: 0 2px 0 0 #e2e2e2;
}

.headerTitle {
  font-size: 14px;
  padding-bottom: 12px;
  padding: 18px 40px 0;
  margin-bottom: 10px;
}

.separator {
  width: 60px;
  height: 1px;
  text-align: center;
  background-color: #d4d6d9;
}

.content {
  width: 110px;
  height: 110px;
  background-color: #fff;
  box-shadow: 0 2px 3px 1px #e6e6e6;
  border-radius: 50%;
  color: #4e66c0;
  font-size: 22px;
}

.footer {
  width: 100%;
  text-align: center;
  padding: 4px 0;
  border-top: 1px solid #F0F0F7;
}

.action_icon {
  color: #1d1d1d;
  font-size: 14px;
}
