@import 'src/styles/breakpoints.scss';

$defaultMargin: 10px;

.wrapper {
  margin-left: -$defaultMargin;
  margin-right: -$defaultMargin;
}

.pageTitle {
  font-weight: 500;
  margin-left: $defaultMargin;
}

.actionCard {
  margin: 16px 10px 0;
  width: 100%;
}
