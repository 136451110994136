.title {
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.container {
  margin: auto;
  text-align: center;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
}

.file_input {
  display: none;
}

.list_group_files {
  margin-top: 1rem;
  padding: 0;
}

.list_group_file_item {
  display: flex !important;
  padding: 0.5rem;
  justify-content: space-between !important;
  border: solid 1px #f4f4f4;
  background: white;
}

.list_group_file_item span {
  cursor: pointer;
}

.custom_buttom {
  width: 120px;
}