.draggableMarkerMapContainer {
  height: 90%;
  width: 100%;
  max-height: 350px;
  min-height: 350px;

  .mapNavigation {
    display: flex;
    justify-content: flex-end;
    padding: 0.5em;
  }

  .mapResetContainer {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }

  &:hover {
    .mapResetContainer {
      visibility: visible;
      opacity: 1;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      padding: 2em;

      .mapResetButton {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 100px 80px rgba(0, 0, 0, 0.3), -20px 20px 40px 40px rgba(0, 0, 0, 0.1), 20px 20px 40px 40px rgbA(0, 0, 0, 0.1);
      }
    }
  }

  .dragLabelContainer {
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: center;

    .dragLabel {
      font-size: 0.75em;
      font-weight: 300;
      margin-bottom: 0;
      padding-left: 0.25em;
    }
  }
}
