@import './breakpoints.scss';

.horizontal-drawer-padding {
  padding-left: 18px;
  padding-right: 18px;
}

@include media-breakpoint-up(md) {
  .horizontal-drawer-padding {
    padding-left: 28px;
    padding-right: 28px;
  }
}
