@import './../../styles/variables';

.stepper {
  .stepHeaderContainer ul {
    align-items: center; /* Default */
    background: $white;
    display: flex;
    justify-content: space-between;
    height: 75px;
    margin: 0;
    padding: 1.5em;
    width: 100%;
  
    .stepHeaderItem {
      align-items: center;
      background: inherit;
      display: flex;
      flex: 1 1 auto;
      font-size: 0.75em;
      font-family: 'Montserrat';
      font-weight: 500;
      list-style-type: none;
      text-transform: uppercase;
      position: relative;

      span {
        width: 10% !important;
      }
  
      &:last-child {
        flex: 0 1 auto;
  
        &::after {
          display: none;
        }
      }
  
      &::after {
        display: block;
        content: "";
        width: 75%;
        height: 1px;
        background-color: $light-gray;
        margin-left: 4em;
      }

      &.mainStepper {
        &::after {
          margin-left: 1.5em;
          width: 80%;
        }
      }
  
      &:hover {
        cursor: pointer;
      }
  
      &.active {
        color: #425cbb;
      }

      .stepHeaderIcon {
        align-items: center;
        color: #000;
        display: flex;
        height: 40px;
        margin-right: 0.3em;
        padding: 10px;
        justify-content: center;
        width: 40px !important;
      }
  
      .stepHeaderIconActive {
        @extend .stepHeaderIcon;
        background-color: #425cbb;
        border-radius: 50px;
        color: white;
      }
    }
  }
  
  .stepContainer {
    background-color: $white;
    padding: 1em;
    margin-top: 0.5rem !important
  }

  &.stepperVerticalAlign {
    margin: 0.5em;
    overflow: hidden;
    .stepHeaderContainer {
      width: 12%;
      float: left;
      height: 100%;
      ul {
        flex-direction: column;
        padding: 0.5em;
        height: 60vh;
        .stepHeaderItem {
          flex-direction: column;
          &:last-child {
            flex: 0 1 auto;
      
            &::after {
              display: none;
            }
          }
          &::after {
            display: block;
            content: "";
            width: 1px;
            height: 60%;
            background-color: $light-gray-2;
            margin: 1em;
          }
          span {
            font-size: 0.8em;
            margin-top: 0.5em;
            width: 100% !important;
          }
        }
      }
    }
    .stepContainer {
      width: 86%;
      margin-left: 2%;
      float: left;
      margin-top: 0rem !important;
      padding: 0rem;
    }
  }
  
  .feedbackIconContainer {
    margin: 18px 0;
  
    i {
      color: #66c687;
      font-size: 86px;
    }
  }
  
  .feedbackTitle {
    overflow-wrap: break-word;
    width: 300px;
    text-align: center;
  }
  
  .feedbackMessage {
    margin-top: 16px;
    width: 400px;
    text-align: center;
  }  
}