.tagline { 
  font-size: 1em;
  font-family: 'Lora';
  font-style: italic;
}

label, .label { 
  font-size: .75em;
  font-weight: 600;
  margin-bottom: 0;
  text-transform: uppercase;
}

